.App {
  text-align: center;
  background-color: #CCB697;
  font-family: 'Roboto Mono', monospace;
  min-height: 100vh;
}

html {
  background-color: #CCB697;
}

.playButton {
  transform: translate(-50%, -50%)
}


